import React from "react"
import Headline from "../components/headline"
import GridTwo from "../components/grid-2"
import Layout from "../components/layout"

const About = () => {
  return (
    <Layout>
      <Headline
        title="History"
        excerpt="Started as RMGR (Ramditta Mal Ganga Ram) - a multi-functional group in Gojra 9 decades ago in the year 1929, Alliance World came into inception in 2003 to further diversify its portfolio into pharmaceuticals, FMCG, agriculture, engineering tech, farming & marketing."
      />

      <section className="section">
        <div className="container">
          <h3 className="is-size-3">About</h3>
          <br />
          <GridTwo
            left="The world of Alliance World Group is one without boundaries – growing, changing and challenging. It specializes in supply chain, contract manufacturing and need based services by not only meeting the current needs of the global customer base but also developing exciting new solutions that deliver additional value to the customers in future.<br/><br/> As one of India’s most geographically diversified group, Alliance World operates world-class manufacturing facilities at Himachal Pradesh, Punjab, Haryana, Assam, Maharashtra & Chandigarh."
            right="Alliance World Group has played a pioneering role in industrial practices in India and has embarked on some path-breaking initiatives and have made a global impact. Driven by a culture of performance, the Alliance World Group is committed to aspiration targets, safety and social responsibility, continuous improvement, openness & transparency."
          />
        </div>
      </section>

      <section className="section" id="vision">
        <div className="container">
          <GridTwo
            left="<span class='dots'></span><hr /><h3 class='is-size-3 has-text-primary'>Vision</h3><br />
            <span class='is-size-4'>To be the preferred choice of partner in Manufacturing,  Supply Chain and Sales & Marketing in select industrial  segments.</span>"
            right="<span class='dots'></span><hr /><h3 class='is-size-3 has-text-primary'>Mission</h3><br />
            <span class='is-size-4'>To attain market leadership by meeting global standards, building long term relationships with our stakeholders and providing quality services to ensure customer satisfaction.</span>"
          />
        </div>
      </section>

      <section
        className="section has-background-light is-large has-background-image"
        id="quality"
        style={{ backgroundImage: "url(./images/slider/3.jpg)" }}
      >
        <div className="container">
          <h3
            className="is-size-3 has-text-primary"
            data-sal="slide-right"
            data-sal-duration="800"
            data-sal-delay="300"
            data-sal-easing="ease"
          >
            Quality Policy
          </h3>
          <br />
          <GridTwo
            left="<span class='has-text-primary'>To ensure business growth through customer satisfaction and its continual enhancement by providing quality services to meet our client's external and internal needs.</span>"
            right="<span class='has-text-primary'>We strive to achieve this by understanding the customer’s requirements thoroughly and implementing appropriate and continually improving Quality Management Systems.</span>"
          />
        </div>
      </section>

      <section
        data-sal="fade"
        data-sal-duration="600"
        data-sal-delay="0"
        data-sal-easing="ease"
        className="section is-medium has-background-primary has-text-white"
      >
        <div className="container" id="companyoverview">
          <h2
            data-sal="slide-right"
            data-sal-duration="800"
            data-sal-delay="300"
            data-sal-easing="ease"
            className="is-size-2 has-text-weight-bold  has-text-centered"
          >
            Company Overview
          </h2>
          <br />
          <br />
          <br />
          <div className="columns is-variable is-multiline is-8">
            <div
              data-sal="slide-right"
              data-sal-duration="800"
              data-sal-delay="200"
              data-sal-easing="ease"
              className="column is-4 mob-margin-bottom"
            >
              {" "}
              <br />
              <br />
              <p className="is-size-4 has-text-weight-light">
                No. of years of experience since its inception in 1929
              </p>
              <br />
              <h2 className="is-size-2 has-text-weight-bold"> 90 Yrs</h2>
            </div>
            <div
              data-sal="slide-right"
              data-sal-duration="800"
              data-sal-delay="300"
              data-sal-easing="ease"
              className="column is-4 mob-margin-bottom"
            >
              {" "}
              <br /> <br />
              <p className="is-size-4 has-text-weight-light">
                Annual turnover as per 2021-2022 financial year
              </p>
              <br />
              <h2 className="is-size-2 has-text-weight-bold"> $270 M</h2>
            </div>
            <div
              data-sal="slide-right"
              data-sal-duration="800"
              data-sal-delay="400"
              data-sal-easing="ease"
              className="column is-4"
            >
              {" "}
              <br /> <br />
              <p className="is-size-4 has-text-weight-light">
                Total no. of group employees as in the year 2022
              </p>
              <br />
              <h2 className="is-size-2 has-text-weight-bold"> 2.1K+</h2>
            </div>
            <div
              data-sal="slide-right"
              data-sal-duration="800"
              data-sal-delay="200"
              data-sal-easing="ease"
              className="column is-4 mob-margin-bottom"
            >
              <br />
              <br />
              <p className="is-size-4 has-text-weight-light">
                No. of multi-national companies we have partnered with
              </p>
              <br />
              <h2 className="is-size-2 has-text-weight-bold"> 10+ MNC</h2>
            </div>
            <div
              data-sal="slide-right"
              data-sal-duration="800"
              data-sal-delay="300"
              data-sal-easing="ease"
              className="column is-4 mob-margin-bottom"
            >
              <br />
              <br />
              <p className="is-size-4 has-text-weight-light">
                No. of manufacturing plants spread across India
              </p>
              <br />
              <h2 className="is-size-2 has-text-weight-bold"> 10+</h2>
            </div>
            <div
              data-sal="slide-right"
              data-sal-duration="800"
              data-sal-delay="400"
              data-sal-easing="ease"
              className="column is-4"
            >
              <br />
              <br />
              <p className="is-size-4 has-text-weight-light">
                No. of product lines owned by Alliance Group
              </p>
              <br />
              <h2 className="is-size-2 has-text-weight-bold"> 10+</h2>
            </div>
          </div>
        </div>
      </section>

      <section className="section" id="leadership">
        <div className="container">
          <h3 className="is-size-3" style={{ marginBottom: "1rem" }}>
            Our Team
          </h3>
          <h3 className="is-size-5">Partners</h3>
          <br />
          <div className="columns is-variable is-8">
            <div
              className="column is-4"
              data-sal="slide-right"
              data-sal-duration="800"
              data-sal-delay="300"
              data-sal-easing="ease"
            >
              <img src={"/team/bod3.jpg"} alt="" />
              <br />
              <br />
              <h4 className="is-size-5 has-text-weight-bold">
                Anil Nagpal
                <br />
                Partner, Alliance World
              </h4>
              <br />
              <p className="is-size-6">
                Anil endeavors to bring new technologies - keeping in view the
                ground reality and requirements of the country at a friendly
                cost to improve the quality of life of the masses.
              </p>
            </div>
            <div
              className="column is-4"
              data-sal="slide-right"
              data-sal-duration="800"
              data-sal-delay="300"
              data-sal-easing="ease"
            >
              <img src={"/team/bod2.jpg"} alt="" />
              <br />
              <br />
              <h4 className="is-size-5 has-text-weight-bold">
                Harish Agarwal
                <br />
                Partner, Alliance World
              </h4>
              <br />
              <p className="is-size-6">
                Harish has engineered the Alliance World group to diversify into
                various activities and is currently handling a 1645 employees
                generating a turnover of USD 170 million.
              </p>
            </div>
          </div>

          <br />
          <hr />
          <h3 className="is-size-5">Board / Directors</h3>
          <br />
          <div className="columns is-variable is-8 is-multiline">
            <div
              className="column is-3"
              data-sal="slide-right"
              data-sal-duration="800"
              data-sal-delay="300"
              data-sal-easing="ease"
            >
              <h4 className="is-size-5 has-text-weight-bold">Satish Loomba</h4>
            </div>
            <div
              className="column is-3"
              data-sal="slide-right"
              data-sal-duration="800"
              data-sal-delay="300"
              data-sal-easing="ease"
            >
              <h4 className="is-size-5 has-text-weight-bold">Karan Agarwal</h4>
            </div>
            <div
              className="column is-3"
              data-sal="slide-right"
              data-sal-duration="800"
              data-sal-delay="300"
              data-sal-easing="ease"
            >
              <h4 className="is-size-5 has-text-weight-bold">S.D. Rampal</h4>
            </div>
            <div
              className="column is-3"
              data-sal="slide-right"
              data-sal-duration="800"
              data-sal-delay="300"
              data-sal-easing="ease"
            >
              <h4 className="is-size-5 has-text-weight-bold">Aankush Nagpal</h4>
            </div>
            <div
              className="column is-3"
              data-sal="slide-right"
              data-sal-duration="800"
              data-sal-delay="300"
              data-sal-easing="ease"
            >
              <h4 className="is-size-5 has-text-weight-bold">
                Madhvi Aggarwal
              </h4>
            </div>
          </div>

          <hr />
          <h3 className="is-size-5">Management Team</h3>
          <br />
          <div className="columns is-variable is-8 is-multiline">
            <div
              className="column is-3"
              data-sal="slide-right"
              data-sal-duration="800"
              data-sal-delay="300"
              data-sal-easing="ease"
            >
              <h4 className="is-size-5 has-text-weight-bold">
                Dr. Malkit Singh
              </h4>
            </div>
            <div
              className="column is-3"
              data-sal="slide-right"
              data-sal-duration="800"
              data-sal-delay="300"
              data-sal-easing="ease"
            >
              <h4 className="is-size-5 has-text-weight-bold">Rajesh Kumar</h4>
            </div>
            <div
              className="column is-3"
              data-sal="slide-right"
              data-sal-duration="800"
              data-sal-delay="300"
              data-sal-easing="ease"
            >
              <h4 className="is-size-5 has-text-weight-bold">S.K. Rastogi</h4>
            </div>
            <div
              className="column is-3"
              data-sal="slide-right"
              data-sal-duration="800"
              data-sal-delay="300"
              data-sal-easing="ease"
            >
              <h4 className="is-size-5 has-text-weight-bold">
                Anis A Quraishi
              </h4>
            </div>
            <div
              className="column is-3"
              data-sal="slide-right"
              data-sal-duration="800"
              data-sal-delay="300"
              data-sal-easing="ease"
            >
              <h4 className="is-size-5 has-text-weight-bold">Asim Gupta</h4>
            </div>
            <div
              className="column is-3"
              data-sal="slide-right"
              data-sal-duration="800"
              data-sal-delay="300"
              data-sal-easing="ease"
            >
              <h4 className="is-size-5 has-text-weight-bold">Arvind Raman</h4>
            </div>
            <div
              className="column is-3"
              data-sal="slide-right"
              data-sal-duration="800"
              data-sal-delay="300"
              data-sal-easing="ease"
            >
              <h4 className="is-size-5 has-text-weight-bold">Ajay Chadha</h4>
            </div>
            <div
              className="column is-3"
              data-sal="slide-right"
              data-sal-duration="800"
              data-sal-delay="300"
              data-sal-easing="ease"
            >
              <h4 className="is-size-5 has-text-weight-bold">Garima Nagpal</h4>
            </div>
            <div
              className="column is-3"
              data-sal="slide-right"
              data-sal-duration="800"
              data-sal-delay="300"
              data-sal-easing="ease"
            >
              <h4 className="is-size-5 has-text-weight-bold">Jose Mathew</h4>
            </div>
          </div>
        </div>
      </section>
    </Layout>
  )
}
export default About
